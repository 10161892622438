import Footer from "../../components/organisms/Footer";
import Header from "../../components/organisms/Header";

const HomePageTemplate = ({ children }) => (
  <div className="home-page-template">
    <Header />
    <main>
      {children}
    </main>
    <Footer />
  </div>
);

export default HomePageTemplate;