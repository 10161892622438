// src/components/AboutUs.js
import React from 'react';
import AboutUSImage1 from '../../assets/images/aboutus1.jpg';
import AboutUSImage2 from '../../assets/images/aboutus2.jpg';
import AboutUSImage3 from '../../assets/images/aboutus3.jpg';

export default function AboutUs() {
    return (
        <div id="about-us" className="grid grid-cols-1 p-4 lg:px-12 mt-12">
            <div className="border-t-8 border-custom-theme w-1/3 lg:w-72 my-4 lg:mx-0"></div>
            <h2 className="text-6xl font-extrabold uppercase mb-4">About Us</h2>
            <h3 className='text-custom-heading text-lg font-medium mt-8'>You'll have everything necessary to find the right agent, enabling you to compare their information side by side before your first <br /> conversation - completely free for homeowners.</h3>
            <div className="flex flex-col lg:flex-row items-center h-auto lg:h-[370px] mt-16">
                <div className="w-full lg:w-1/2 p-6 bg-gray-100 flex flex-col items-center justify-center rounded h-full order-2 lg:order-1">
                    <h1 className="font-bold text-2xl lg:text-6xl text-custom-heading uppercase">LIST</h1>
                    <p className="mt-2 text-custom-heading text-xl lg:text-2xl font-medium text-center max-w-xs">
                        Customers list their property details and preferences on feedom.
                    </p>
                </div>
                <div className="w-full lg:w-1/2 h-full order-1 lg:order-2">
                    <img src={AboutUSImage1} alt="Property" className="h-full w-full object-cover image-zoom" />
                </div>
            </div>
            <div className="flex flex-col lg:flex-row items-center h-auto lg:h-[370px] mt-8 lg:mt-0">
                <div className="w-full lg:w-1/2 h-full">
                    <img src={AboutUSImage2} alt="Property" className="h-full w-full object-cover image-zoom" />
                </div>
                <div className="w-full lg:w-1/2 p-6 bg-gray-100 flex flex-col items-center justify-center rounded h-full">
                    <h1 className="font-bold text-2xl lg:text-6xl text-custom-heading uppercase">Compare</h1>
                    <p className="mt-2 text-custom-heading text-xl lg:text-2xl font-medium text-center max-w-xs">
                        They compare different fee-saving options and services available.
                    </p>
                </div>
            </div>
            <div className="flex flex-col lg:flex-row items-center h-auto lg:h-[370px] mt-8 lg:mt-0">
                <div className="w-full lg:w-1/2 h-full order-1 lg:order-2">
                    <img src={AboutUSImage3} alt="Property" className="h-full w-full object-cover image-zoom" />
                </div>
                <div className="w-full lg:w-1/2 p-6 bg-gray-100 flex flex-col items-center justify-center rounded h-full order-2 lg:order-1">
                    <h1 className="font-bold text-2xl lg:text-6xl text-custom-heading uppercase">Save</h1>
                    <p className="mt-2 text-custom-heading text-xl lg:text-2xl font-medium text-center max-w-xs">
                        Finally, they choose the best option and start saving on fees.
                    </p>
                </div>
            </div>
        </div>
    )
}
