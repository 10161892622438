import React from 'react';
import Svgs from '../../assets/svgs';

const Footer = () => {
  return (
    <footer className="w-full bg-white border-t border-custom-heading p-4 mb-12 lg:px-12">
      <div className="container mx-auto py-4 flex flex-col lg:flex-row justify-between items-start lg:items-center space-y-6 lg:space-y-0">
        <div className="flex space-x-8">
          <a href="#" className="text-custom-heading font-medium text-base hover:text-gray-900">Home</a>
          <a href="#" className="text-custom-heading font-medium text-base hover:text-gray-900">Privacy</a>
          <a href="#" className="text-custom-heading font-medium text-base hover:text-gray-900">Terms of use</a>
        </div>
        <div className="flex space-x-8 mt-8 lg:mt-0">
          <a href="#" className="text-gray-600 hover:text-gray-900">
            <Svgs.Dribble />
          </a>
          <a href="#" className="text-gray-600 hover:text-gray-900">
            <Svgs.Linkdein />
          </a>
          <a href="#" className="text-gray-600 hover:text-gray-900">
            <Svgs.Twitter />
          </a>
          <a href="#" className="text-gray-600 hover:text-gray-900">
            <Svgs.Insta />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
