// src/components/Testimonials.js
import React, { useEffect, useRef, useState } from 'react';
import Testimonial1 from '../../assets/images/testimonial.png';
import Svgs from '../../assets/svgs';

const testimonials = [
  {
    text: "I can’t imagine how fast TA helped me find the best agent nearby and the way they helped me save money on my deal. Amazing!",
    author: "Andy Smith",
    title: "Founder, Apple",
    image: Testimonial1,
  },
  {
    text: "TA made my home search effortless, connecting me with a fantastic agent who saved me so much money. Truly remarkable!",
    author: "John Doe",
    title: "CEO, Example Inc.",
    image: Testimonial1,
  },
];

const TestimonialSection = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [animate, setAnimate] = useState(false);
  const [lineAnimate, setLineAnimate] = useState(false); // State for line animation
  const testimonialRef = useRef(null);

  const handleNext = () => {
    setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
    setAnimate(true);
  };

  const handlePrev = () => {
    setCurrentTestimonial((prev) => (prev - 1 + testimonials.length) % testimonials.length);
    setAnimate(true);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setLineAnimate(true); // Trigger line animation
        }
      },
      { threshold: 0.1 }
    );

    if (testimonialRef.current) {
      observer.observe(testimonialRef.current);
    }

    return () => {
      if (testimonialRef.current) {
        observer.unobserve(testimonialRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (animate) {
      const timeout = setTimeout(() => setAnimate(false), 600);
      return () => clearTimeout(timeout);
    }
  }, [animate]);

  return (
    <div id="testimonials" className="flex flex-col py-10 px-4 lg:px-16" ref={testimonialRef}>
      <div
        className={`border-t-8 border-custom-theme w-1/3 lg:w-52 my-4 lg:mx-0 ${lineAnimate ? 'line-animation' : 'w-0'}`} // Add line animation class
      ></div>
      <h2 className="text-4xl lg:text-6xl font-extrabold uppercase">Testimonials</h2>
      <h3 className='text-custom-heading text-lg font-medium mt-6'>What people say about us</h3>

      <div className="flex flex-col md:flex-row justify-center w-full mb-16 mt-16">
        <div className="w-full md:w-1/2 flex flex-col items-start pr-4">
          <img
            src={testimonials[currentTestimonial].image}
            alt={testimonials[currentTestimonial].author}
            className="w-auto h-[200px] md:h-[400px] object-cover rounded-md border border-gray-300 mb-4 image-zoom"
          />
        </div>
        <div className="w-full md:w-1/2 flex flex-col justify-center items-start pl-4 gap-8">
          <div className="text-yellow-500 text-4xl lg:text-6xl">
            <Svgs.QuoteIcon />
          </div>
          <div className={`${animate ? 'animate-fade-slide' : ''} h-[13rem]`}>
            <p className="text-xl lg:text-3xl text-heading-theme font-medium mb-4 max-w-lg">
              {testimonials[currentTestimonial].text}
            </p>
          </div>
          <div className="flex flex-row items-center justify-between w-full">
            <div className="lg:text-left">
              <p className="text-lg lg:text-xl text-custom-theme font-normal">{testimonials[currentTestimonial].author}</p>
              <p className="text-custom-heading text-sm lg:text-lg font-medium">{testimonials[currentTestimonial].title}</p>
            </div>
            <div className="flex space-x-4 lg:mt-0">
              <button onClick={handlePrev} className="lg:block hidden image-zoom"><Svgs.TreminalLeft /></button>
              <button onClick={handleNext} className="lg:block hidden image-zoom"><Svgs.TerminalRight /></button>
              <button onClick={handlePrev} className="lg:hidden block image-zoom"><Svgs.TreminalLeftMobile /></button>
              <button onClick={handleNext} className="lg:hidden block image-zoom"><Svgs.TerminalRightMobile /></button>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center mb-8">
        <h3 className="font-bold text-3xl lg:text-7xl text-custom-heading mb-4 leading-tight max-w-6xl">
          Get more cash in your pocket when you sell your house.
        </h3>
        <div className="flex w-full justify-center mt-12">
          <div className="max-w-4xl flex w-full">
            <input type="email" placeholder="Your email" className="p-3 border-2 font-semibold text-sm lg:text-lg lg:text-xl text-custom-heading rounded-l-md w-1/2 outline-none border-custom-theme" />
            <button className="bg-custom-theme text-sm lg:text-lg text-custom-heading p-3 rounded-r-md w-1/2 font-bold signup-btn">START SAVING NOW</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSection;
