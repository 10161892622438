const Linkdein = () => {
    return <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.7099e-07 2.50649C3.7099e-07 1.84176 0.264063 1.20426 0.734097 0.734222C1.20413 0.264188 1.84163 0.000125412 2.50636 0.000125412H27.4909C27.8203 -0.000412613 28.1466 0.0640294 28.4511 0.189762C28.7556 0.315494 29.0323 0.500048 29.2653 0.732857C29.4984 0.965665 29.6833 1.24216 29.8093 1.5465C29.9354 1.85085 30.0002 2.17707 30 2.50649V27.491C30.0004 27.8205 29.9357 28.1468 29.8099 28.4513C29.684 28.7558 29.4993 29.0325 29.2664 29.2655C29.0335 29.4986 28.7569 29.6834 28.4525 29.8095C28.148 29.9355 27.8218 30.0003 27.4923 30.0001H2.50636C2.17711 30.0001 1.85108 29.9352 1.5469 29.8092C1.24273 29.6832 0.96637 29.4984 0.733615 29.2655C0.50086 29.0327 0.316271 28.7562 0.190394 28.452C0.0645181 28.1477 -0.000178767 27.8216 3.7099e-07 27.4924V2.50649ZM11.8745 11.4383H15.9368V13.4783C16.5232 12.3056 18.0232 11.2501 20.2773 11.2501C24.5986 11.2501 25.6227 13.586 25.6227 17.8719V25.811H21.2495V18.8483C21.2495 16.4074 20.6632 15.0301 19.1741 15.0301C17.1082 15.0301 16.2491 16.5151 16.2491 18.8483V25.811H11.8745V11.4383ZM4.37455 25.6242H8.74909V11.2501H4.37455V25.6229V25.6242ZM9.375 6.56194C9.38325 6.9365 9.3166 7.30892 9.17896 7.65737C9.04133 8.00582 8.83548 8.32326 8.5735 8.59108C8.31152 8.85889 7.99868 9.07168 7.65335 9.21695C7.30802 9.36223 6.93714 9.43706 6.5625 9.43706C6.18786 9.43706 5.81698 9.36223 5.47165 9.21695C5.12632 9.07168 4.81348 8.85889 4.5515 8.59108C4.28952 8.32326 4.08367 8.00582 3.94604 7.65737C3.8084 7.30892 3.74175 6.9365 3.75 6.56194C3.76619 5.82674 4.06962 5.12711 4.5953 4.61288C5.12098 4.09864 5.82712 3.81069 6.5625 3.81069C7.29788 3.81069 8.00402 4.09864 8.5297 4.61288C9.05538 5.12711 9.35881 5.82674 9.375 6.56194Z" fill="#2C363F"/>
    </svg>
    
  }
const Dribble = () => {
    return <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_390_5238)">
    <path d="M20.4609 8.84945C19.4695 7.96735 18.279 7.33872 16.9915 7.01742C15.7039 6.69611 14.3577 6.69171 13.0681 7.00459C13.2958 7.30927 14.7924 9.34499 16.1417 11.826C19.0714 10.7278 20.3103 9.06039 20.4609 8.84945ZM16.4062 16.3662C11.7623 17.9834 10.0915 21.2077 9.9442 21.5091C11.3404 22.5972 13.0949 23.2468 15 23.2468C16.1417 23.2468 17.2299 23.0157 18.2176 22.5939C18.0938 21.8707 17.6183 19.3528 16.4598 16.3461L16.4062 16.3662ZM18.0033 15.9678C19.0815 18.9276 19.5201 21.3383 19.6038 21.8405C21.452 20.5916 22.7679 18.6128 23.1328 16.3193C22.8516 16.2323 20.5848 15.5559 18.0033 15.9678ZM15 0.00012207C6.71652 0.00012207 0 6.71664 0 15.0001C0 23.2836 6.71652 30.0001 15 30.0001C23.2835 30.0001 30 23.2836 30 15.0001C30 6.71664 23.2835 0.00012207 15 0.00012207ZM15 24.643C9.68304 24.643 5.35714 20.3171 5.35714 15.0001C5.35714 9.68316 9.68304 5.35726 15 5.35726C20.317 5.35726 24.6429 9.68316 24.6429 15.0001C24.6429 20.3171 20.317 24.643 15 24.643ZM16.7779 13.0515C16.9688 13.4432 17.1529 13.8416 17.3237 14.2434C17.3839 14.3841 17.4442 14.5247 17.5011 14.6686C20.24 14.3238 22.9654 14.8762 23.2366 14.9332C23.2199 12.9878 22.5234 11.2032 21.3783 9.80035C21.2009 10.0381 19.8214 11.8093 16.7779 13.0515ZM14.5513 12.3216C13.1853 9.8941 11.7154 7.85503 11.4978 7.55704C9.1942 8.64521 7.46987 10.768 6.9375 13.3227C7.3058 13.3294 10.7009 13.3461 14.5513 12.3216ZM15.5759 15.0838C15.683 15.0503 15.7902 15.0169 15.9007 14.9867C15.6931 14.518 15.4688 14.0492 15.2344 13.5905C11.1228 14.8227 7.12835 14.7691 6.77009 14.7624C6.76674 14.8461 6.76674 14.9298 6.76674 15.0135C6.76674 17.1296 7.56696 19.0615 8.88281 20.5213C9.06696 20.1999 11.327 16.4566 15.5759 15.0838Z" fill="#2C363F"/>
    </g>
    <defs>
    <clipPath id="clip0_390_5238">
    <rect width="30" height="30" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
  }
  const Insta = () => {
    return  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.9962 9.99799C12.2419 9.99799 9.99412 12.2458 9.99412 15.0001C9.99412 17.7545 12.2419 20.0023 14.9962 20.0023C17.7506 20.0023 19.9984 17.7545 19.9984 15.0001C19.9984 12.2458 17.7506 9.99799 14.9962 9.99799ZM29.9989 15.0001C29.9989 12.9287 30.0176 10.8761 29.9013 8.80844C29.785 6.40682 29.2371 4.27538 27.4809 2.51919C25.721 0.75925 23.5933 0.215133 21.1917 0.0988041C19.1203 -0.0175245 17.0676 0.00123824 15 0.00123824C12.9286 0.00123824 10.876 -0.0175245 8.80832 0.0988041C6.40669 0.215133 4.27525 0.763003 2.51907 2.51919C0.759128 4.27913 0.215011 6.40682 0.098682 8.80844C-0.0176466 10.8798 0.00111617 12.9325 0.00111617 15.0001C0.00111617 17.0678 -0.0176466 19.1242 0.098682 21.1918C0.215011 23.5934 0.762881 25.7249 2.51907 27.4811C4.27901 29.241 6.40669 29.7851 8.80832 29.9014C10.8797 30.0178 12.9324 29.999 15 29.999C17.0714 29.999 19.124 30.0178 21.1917 29.9014C23.5933 29.7851 25.7247 29.2372 27.4809 27.4811C29.2409 25.7211 29.785 23.5934 29.9013 21.1918C30.0214 19.1242 29.9989 17.0715 29.9989 15.0001ZM14.9962 22.6966C10.7371 22.6966 7.2998 19.2593 7.2998 15.0001C7.2998 10.741 10.7371 7.30367 14.9962 7.30367C19.2554 7.30367 22.6927 10.741 22.6927 15.0001C22.6927 19.2593 19.2554 22.6966 14.9962 22.6966ZM23.0079 8.78592C22.0135 8.78592 21.2104 7.98288 21.2104 6.98846C21.2104 5.99404 22.0135 5.19099 23.0079 5.19099C24.0023 5.19099 24.8054 5.99404 24.8054 6.98846C24.8057 7.22459 24.7594 7.45846 24.6692 7.67667C24.5789 7.89488 24.4465 8.09315 24.2796 8.26012C24.1126 8.42709 23.9143 8.55948 23.6961 8.6497C23.4779 8.73993 23.244 8.78622 23.0079 8.78592Z" fill="#2C363F"/>
    </svg>
  }
  const Twitter = () => {
    return <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30 3.66997C28.8968 4.15883 27.7116 4.48914 26.4657 4.63844C27.7513 3.8692 28.713 2.65852 29.1716 1.23229C27.9638 1.94971 26.6419 2.45469 25.2634 2.72529C24.3364 1.7355 23.1085 1.07946 21.7704 0.859006C20.4324 0.638555 19.059 0.866032 17.8634 1.50612C16.6679 2.14621 15.7171 3.16309 15.1588 4.3989C14.6004 5.63471 14.4656 7.0203 14.7754 8.34054C12.328 8.21766 9.93389 7.58156 7.74829 6.47351C5.5627 5.36546 3.63451 3.81024 2.08888 1.90876C1.56038 2.82042 1.2565 3.87741 1.2565 5.0031C1.25591 6.01648 1.50546 7.01434 1.98302 7.90815C2.46057 8.80195 3.15136 9.56406 3.9941 10.1269C3.01675 10.0958 2.06096 9.83167 1.20629 9.35658V9.43585C1.20619 10.8572 1.69783 12.2347 2.59779 13.3348C3.49775 14.4349 4.7506 15.1897 6.14375 15.4713C5.2371 15.7166 4.28654 15.7528 3.36387 15.577C3.75693 16.7999 4.52259 17.8693 5.55364 18.6355C6.5847 19.4017 7.82954 19.8263 9.11389 19.8498C6.93363 21.5614 4.24102 22.4898 1.46922 22.4857C0.978219 22.4858 0.487637 22.4572 0 22.3998C2.81354 24.2088 6.08871 25.1689 9.43363 25.1652C20.7566 25.1652 26.9466 15.787 26.9466 7.6535C26.9466 7.38925 26.94 7.12236 26.9281 6.85812C28.1322 5.98739 29.1715 4.90915 29.9974 3.67394L30 3.66997Z" fill="#2C363F"/>
    </svg>
    
  }
  const QuoteIcon = () => {
    return <svg width="45" height="37" viewBox="0 0 45 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.8177 5.90538C6.97345 2.4842 11.7487 0.75 18.0085 0.75H20.2578V7.09076L18.4493 7.45289C15.3678 8.0692 13.2242 9.28157 12.0771 11.0608C11.4785 12.0192 11.1391 13.1167 11.0919 14.2458H18.0085C18.605 14.2458 19.1772 14.4827 19.599 14.9046C20.0208 15.3264 20.2578 15.8985 20.2578 16.4951V32.2401C20.2578 34.7211 18.2402 36.7387 15.7592 36.7387H2.26344C1.66689 36.7387 1.09477 36.5017 0.672946 36.0799C0.251121 35.6581 0.0141432 35.0859 0.0141432 34.4894V23.2429L0.0208911 16.6772C0.000647464 16.4276 -0.426718 10.5119 3.8177 5.90538ZM40.5014 36.7387H27.0057C26.4091 36.7387 25.837 36.5017 25.4152 36.0799C24.9933 35.6581 24.7564 35.0859 24.7564 34.4894V23.2429L24.7631 16.6772C24.7429 16.4276 24.3155 10.5119 28.5599 5.90538C31.7157 2.4842 36.4909 0.75 42.7507 0.75H45V7.09076L43.1916 7.45289C40.11 8.0692 37.9665 9.28157 36.8193 11.0608C36.2208 12.0192 35.8813 13.1167 35.8341 14.2458H42.7507C43.3473 14.2458 43.9194 14.4827 44.3412 14.9046C44.763 15.3264 45 15.8985 45 16.4951V32.2401C45 34.7211 42.9824 36.7387 40.5014 36.7387Z" fill="#FFBF00"/>
    </svg>
  }
  const TreminalLeft=() => {
    return <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="65" height="65" rx="32.5" fill="#FFBF00"/>
    <path d="M37.9157 21.6665L26.4805 33.1017L37.9157 44.5369" stroke="#2C363F" stroke-width="3.66667"/>
    </svg>
  }
  const TreminalLeftMobile=() => {
    return <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#FFBF00"/>
    <path d="M23.3333 13.3333L16.2963 20.3703L23.3333 27.4073" stroke="#2C363F" stroke-width="3.66667"/>
    </svg>
    
  }
  const TerminalRight=()=>{
    return <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="65" height="65" rx="32.5" fill="#FFBF00"/>
    <path d="M27.0843 44.5371L38.5195 33.1019L27.0843 21.6667" stroke="#2C363F" stroke-width="3.66667"/>
    </svg>
    
  }
  const TerminalRightMobile=()=>{
    return <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#FFBF00"/>
    <path d="M16.6667 27.4075L23.7037 20.3704L16.6667 13.3334" stroke="#2C363F" stroke-width="3.66667"/>
    </svg>
  }
  const SidebarArrow=() => {
    return <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 19L10 10L1 0.999999" stroke="#FFBF00" stroke-width="2"/>
    </svg>
    
  }
 
  export default{
    Linkdein,
    Twitter,
    Insta,
    Dribble,
    QuoteIcon,
    TreminalLeft,
    TerminalRight,
    TreminalLeftMobile,
    TerminalRightMobile,
    SidebarArrow,
  }