import AboutUs from "../../components/organisms/AboutUs";
import HeroSection from "../../components/organisms/HeroSection";
import HowItWorks from "../../components/organisms/HowItWorks";
import TestimonialSection from "../../components/organisms/Testimonials ";
import HomePageTemplate from "./HomePageTemplate";


const Home = () => (
  <HomePageTemplate>
    <HeroSection />
    <HowItWorks />
    <AboutUs/>
    <TestimonialSection/>
  </HomePageTemplate>
);

export default Home;
