// src/components/Hero.jsx

import React from 'react';
import heroImage from '../../assets/images/hero.png';
import { ReactComponent as DropArrowIcon } from '../../assets/svgs/scrollDown.svg';

const HeroSection = () => {
  return (
    <div className="flex flex-col xl:flex-row justify-between p-4 lg:px-16 lg:py-16 bg-white">
      <div className="xl:w-1/2 w-full flex flex-col justify-between xl:text-left lg:py-24">
        <div>
          <div className="border-t-8 border-custom-theme w-[200px] my-4  lg:mx-0"></div>
          <h1 className="text-4xl font-extrabold text-custom-heading tracking-widest	 lg:text-7xl  mb-4">SELL FAST.<br />SAVE MORE.</h1>
        </div>
        <div>
          <p className="text-xl text-custom-heading mb-8">Let top agents compete for your listing, <br /> so you get the best deal effortlessly!</p>
        </div>
        <div className="flex flex-col lg:justify-start xl:w-1/2">
          <input
            type="email"
            placeholder="Your email"
            className="p-3 border-2 font-semibold text-xl text-custom-heading border-custom-theme rounded mb-4 w-full sm:w-auto outline-none"
          />
          <button className="bg-custom-theme text-xl font-bold tracking-wider	 text-custom-heading py-3 px-6 rounded signup-btn">START SAVING NOW</button>
        </div>
      </div>
      <div className="relative xl:w-1/2 mt-8 lg:mt-0  justify-end hidden lg:flex">
        <div className="relative w-full">
          <img
            src={heroImage}
            alt="Aerial view of houses "
            className="h-[700px] w-full rounded-tl-lg object-cover image-zoom"
          />
          <a href="#about-us">
            <DropArrowIcon className="absolute bottom-24 -right-12 text-custom-theme cursor-pointer image-zoom" />
          </a>

        </div>
      </div>
    </div>
  );
};

export default HeroSection;
