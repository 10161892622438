import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';

function App() {
  return (
    <div className="font-sans">
  <Home/>
    </div>
  
  )
}

export default App;
