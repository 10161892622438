// src/components/HowItWorks.js
import React from 'react';
import HowItWork from '../../assets/images/howitwork.png';
import HowItWorkMobile from '../../assets/images/howitworkmobile.png';

const HowItWorks = () => {
  return (
    <div id="how-it-works" className="bg-custom-heading text-white p-4 py-16 lg:px-16">
      <div className="">
        <div className="border-t-8 border-custom-theme w-1/3 lg:w-36 my-4  lg:mx-0"></div>
        <h2 className="text-6xl font-extrabold mb-4">HOW IT WORKS</h2>
        <p className='font-sans font-normal text-[18px] text-[#FFFFFF]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
        <img loading='lazy' src={HowItWorkMobile} className='md:hidden' />
        <img loading='lazy' src={HowItWork} className='hidden md:block' />
      </div>
    </div>
  );
};

export default HowItWorks;
